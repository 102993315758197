import styled from "styled-components";

export default ({ children, ...rest } = {}) => {
  return (
    <Wrapper {...rest}>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export const HEADER_HEIGHT = "2rem";

const Wrapper = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Content = styled.div`
  max-width: ${(p) => p.theme.maxWidth};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  height: 100%;
`;
